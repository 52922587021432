
import Vue from 'vue'
import { Message } from 'element-ui'
import ResourceSelectionModal from '../resource-selection-modal/index.vue'
import type {
  DesignFile,
  DesignFileView,
  SimulationResource,
  UploadFile,
} from '@/api/customized-simulation-resources'
import { getSimulationResourceDetails } from '@/api/customized-simulation-resources'
import * as api from '@/api'
import {
  SimulationResourceCategory,
  SimulationResourceCategoryText,
} from '@/types/simulation'
import type { SimulationTrainingCoursePage } from '@/api/simulation-training'
import { safeJsonParse } from '@/utils/safe-json-parse'
export default Vue.extend({
  components: { ResourceSelectionModal },
  data() {
    return {
      tableData: [{ count: 0 }],
      questions: [] as any[],
      resourceSelectionModalVisible: false,
    }
  },
  computed: {
    currentSimulationResource() {
      return this.$store.state.simulationTrainingModule
        .activeSimulationTrainingCoursePage
    },
    isPublished() {
      return (
        this.$store.state.simulationTrainingModule.selectedSimulationTraining
          .status === 1
      )
    },
  },
  watch: {
    currentSimulationResource: {
      handler(val: SimulationTrainingCoursePage) {
        if (
          ![
            SimulationResourceCategory.homePage,
            SimulationResourceCategory.loadingPage,
          ].includes(val.classType)
        )
          this.init()
      },
      deep: true,
    },
  },
  async mounted() {
    this.init()
  },
  methods: {
    getCategory(type) {
      return SimulationResourceCategoryText[type]
    },
    async init() {
      this.questions = safeJsonParse(
        this.currentSimulationResource.viewSettings,
      )
      this.tableData = [{ count: this.questions.length }]
      // this.$set(this.tableData[0], "count", this.questions.length);
      // this.tableData[0].count = this.questions.length
      // const res = await getSimulationResourceDetails({
      //   id: this.currentSimulationResource.designFileId,
      // })
      // if (res && !api.error(res))
      //   this.tableData = [res.data]
      // else
      //   Message.error(res.error.message)
    },
    async handleResourceSelectConfirm(item) {
      const activeSimulationTrainingCoursePage: SimulationTrainingCoursePage
        = this.$store.state.simulationTrainingModule
          .activeSimulationTrainingCoursePage
      await this.$store.dispatch(
        'simulationTrainingModule/createEditSimulationTrainingCoursePage',
        {
          classType: SimulationResourceCategory.exercises,
          designTaskId: activeSimulationTrainingCoursePage.designTaskId,
          sort: activeSimulationTrainingCoursePage.sort,
          viewSettings: JSON.stringify(item),
          id: activeSimulationTrainingCoursePage.id,
          designFileId: item.designFile?.id,
        },
      )
      // this.tableData[0].count = item.length
      this.init()
    },
    handleReplace(item: DesignFile) {
      this.resourceSelectionModalVisible = true
    },
    viewModel(item: UploadFile) {
      this.$router.push({ path: '/model-view', query: { id: item.fileId } })
    },
  },
})
