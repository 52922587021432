
import { SimulationTrainingCoursePage } from "@/api/simulation-training";
import { SimulationResourceCategory } from "@/types/simulation";
import { safeJsonParse } from "@/utils/safe-json-parse";
import { uploadFile } from "@/utils/upload-file";
import { Message } from "element-ui";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      courseIntroduction: "",
      taskRequirements: "",
      filePath: "",
      uploadableExtensions: [".png", ".jpg", ".jpeg", ".mp4"]
    };
  },
  computed: {
    isVideo(): boolean {
      const index = this.filePath.lastIndexOf("%2E") + 3;
      return this.filePath.slice(index) === "mp4";
    },
    activeSimulationTrainingCoursePage() {
      return this.$store.state.simulationTrainingModule.activeSimulationTrainingCoursePage;
    },
    isPublished() {
      return this.$store.state.simulationTrainingModule.selectedSimulationTraining.status === 1;
    }
  },
  methods: {
    uploadModel(_file) {
      const file = _file.raw;
      const index = file.name.lastIndexOf(".");
      const type: string = file.name.slice(index);
      if (!this.uploadableExtensions.some(v => v === type.toLowerCase())) {
        this.$message.warning(`暂时不支持该格式, 请上传${this.uploadableExtensions.join(" ")}文件`);
        return;
      }
      const setFile = data => {
        this.filePath = process.env.VUE_APP_BORON + "/" + data.filePath;
      };
      uploadFile(file, setFile, this.$store.state.sessionId);
    },
    async handleSave() {
      if (this.courseIntroduction === "" || this.taskRequirements === "" || this.filePath === "") {
        Message.warning("请输入内容或上传图片");
        return;
      }
      const activeSimulationTrainingCoursePage = this.$store.state.simulationTrainingModule
        .activeSimulationTrainingCoursePage;
      await this.$store.dispatch(
        "simulationTrainingModule/createEditSimulationTrainingCoursePage",
        {
          ...activeSimulationTrainingCoursePage,
          viewSettings: JSON.stringify({
            courseIntroduction: this.courseIntroduction,
            taskRequirements: this.taskRequirements,
            filePath: this.filePath
          })
        }
      );
      Message.success("保存成功");
    },
    deleteFile() {
      this.filePath = "";
    }
  },
  watch: {
    activeSimulationTrainingCoursePage: {
      handler(val: SimulationTrainingCoursePage) {
        if (val.classType === SimulationResourceCategory.homePage) {
          const viewSettings = safeJsonParse(val.viewSettings);
          this.courseIntroduction = viewSettings.courseIntroduction || "";
          this.taskRequirements = viewSettings.taskRequirements || "";
          this.filePath = viewSettings.filePath || "";
        }
      },
      immediate: true
    }
  }
});
