
import { UserDetail } from "@/api/simulation-training";
import { CompletionStatus, CompletionStatusText } from "@/types/simulation";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {};
  },
  methods: {
    getStatus(item: UserDetail) {
      return CompletionStatusText[item.designFinishStatus];
    },
    getStatusColor(status: CompletionStatus) {
      switch (status) {
        case CompletionStatus.notStart:
          return "#666666";
        case CompletionStatus.studying:
          return "#FFA136";
        case CompletionStatus.completed:
          return "#02D6DC";
        default:
          break;
      }
    }
  },
  computed: {
    tableData(): UserDetail[] {
      return this.$store.state.simulationTrainingModule.memberList.filter(
        v => v.designFinishStatus === this.$store.state.simulationTrainingModule.completeStatus
      );
    }
  }
});
