
import Vue from "vue";
import QuestionItem from "@/features/QuestionBank/QuestionItem.vue";
import type { PropType } from "vue/types/v3-component-props";
import { ibuidingAPI } from "@/api/ibuidingAPI";
import * as api from "@/api";
import { Message } from "element-ui";
import type { Building3DApplicationDtosStudyManagerDesignQuestionsDto } from "@/api/ibuilding3d-api";
import draggable from "vuedraggable";
export default Vue.extend({
  components: { QuestionItem, draggable },
  props: {
    selectedIds: { type: Array as PropType<string[]> },
  },
  data() {
    return {
      selectedQuestionsArr:
        [] as Building3DApplicationDtosStudyManagerDesignQuestionsDto[],
    };
  },
  computed: {
    counts(): number {
      return this.selectedIds.length;
    },
  },
  methods: {
    confirm() {
      const result = this.selectedQuestionsArr.map((item) => item.id);
      this.$emit("changedSort", result);
    },
    openDialog() {
      const val = this.selectedIds;
      if (val) {
        this.getQuestions({
          ids: val,
        });
      }
    },
    async getQuestions({ ids }) {
      const res = await ibuidingAPI.vAStudyDesignQuestionsPageListCreate({
        maxResultCount: 999,
        skipCount: 0,
        isSortByIds: true,
        ids: ids,
      });
      if (res && !api.error(res)) {
        // console.log(`asdasd is `, res.data.items);
        this.selectedQuestionsArr = res.data.items!;
      } else Message.error(res.error.message);
    },
  },
});
