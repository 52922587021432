
import { ElTree } from "element-ui/types/tree";
import { difference, isEqual } from "lodash";
import Vue, { PropType } from "vue";
interface TreeItem {
  id: string;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  children?: TreeItem[];
}

export default Vue.extend({
  props: {
    visible: Boolean,
    classesAndStudents: { type: Array as PropType<TreeItem[]> }
  },
  watch: {
    filterText(val) {
      (this.$refs.tree as any).filter(val);
    }
  },
  computed: {
    defaultSelected() {
      return this.classesAndStudents
        .map(v => v.children)
        .flat()
        .filter(v => v?.checked)
        .map(v => v?.id);
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleCheckChange(a, { checkedNodes }) {
      const checkedStudentIds = checkedNodes
        .filter(({ isStudent = false }) => Boolean(isStudent))
        .map(({ id }) => id);
      this.checkedStudentIds = checkedStudentIds;
    },
    handleConfirm() {
      if (isEqual(this.checkedStudentIds, this.defaultSelected)) {
        this.$message.warning("请先选择要添加的成员！");
        return;
      }
      const newAddStudentsIds = difference(this.checkedStudentIds, this.defaultSelected).map(
        v => v?.split("_")[1]
      );
      this.$emit("onConfirm", newAddStudentsIds);
    }
  },
  data() {
    return {
      filterText: "",
      defaultProps: {
        children: "children",
        label: "label"
        // id: "id"
      },
      checkedStudentIds: []
    };
  }
});
