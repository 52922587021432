
import { UserDetail } from "@/api/simulation-training";
import { CompletionStatus } from "@/types/simulation";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    notStartList(): UserDetail[] {
      return this.$store.getters["simulationTrainingModule/notStartList"];
    },
    studyingList(): UserDetail[] {
      return this.$store.getters["simulationTrainingModule/studyingList"];
    },
    completedList(): UserDetail[] {
      return this.$store.getters["simulationTrainingModule/completedList"];
    },
    completeStatus(): CompletionStatus {
      return this.$store.state.simulationTrainingModule.completeStatus;
    }
  },
  methods: {
    setStatus(status) {
      this.$store.commit("simulationTrainingModule/setCompleteStatus", status);
    }
  }
});
