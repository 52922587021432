
import Vue, { PropType } from "vue";
import MemberConfigureAddModal from "@/features/collage/task-detail/member-configure-add-modal.vue";
import { getClassList, getMemberList } from "@/api/Classes";
import { addTaskMembers } from "@/api/Jobs";
import * as api from "@/api";
import { Member, Task } from "@/features/collage/types";
interface TreeItem {
  id: string;
  label: string;
  children?: TreeItem[];
}
export default Vue.extend({
  components: { MemberConfigureAddModal },
  props: {
    memberList: { type: Array as PropType<Member[]> }
  },
  data() {
    return {
      visible: false,
      classesAndStudents: [] as TreeItem[]
    };
  },
  methods: {
    async handleAddMemberClick() {
      const res = await getClassList({ skipCount: 0, maxResultCount: 999 });
      if (res && !api.error(res)) {
        const classList = res.data.items;
        const promises = classList.map(v => {
          return getMemberList({
            skipCount: 0,
            maxResultCount: 999,
            classId: v.id,
            isEnabled: true,
            inClass: true
          });
        });
        const memberRes: { data: { items: Member[] } }[] = (await Promise.all(promises)) as any;
        if (memberRes.every(res => res && !api.error(res))) {
          this.classesAndStudents = classList.map((v, index) => {
            return {
              id: v.id,
              label: `${v.name}（${memberRes[index].data.items.length}人）`,
              disabled: memberRes[index].data.items
                .map(({ userId }) => userId)
                .every(id => this.memberList?.some(({ userId }) => id === userId)),
              children: memberRes[index].data.items.map(({ userId, nickName }) => ({
                id: `${v.id}_${userId}`,
                label: nickName,
                isStudent: true,
                checked: this.memberList?.some(({ userId: existedId }) => existedId === userId),
                disabled: this.memberList?.some(({ userId: existedId }) => existedId === userId)
              }))
            };
          });
        }
      }
      this.visible = true;
    },
    async handleConfirm(checkedStudentIds) {
      await this.$store.dispatch("simulationTrainingModule/addMember", {
        designTaskId: this.$store.state.simulationTrainingModule.selectedSimulationTraining.id,
        targetIds: checkedStudentIds
      });
      this.visible = false;
      this.$message.success("添加成员成功！");
    }
  }
});
