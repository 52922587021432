
import Vue from 'vue'
import Draggable from 'vuedraggable'
import ResourceSelectionModal from '../resource-selection-modal/index.vue'
import { ibuidingAPI } from '@/api/ibuidingAPI'
import type { SimulationTrainingCoursePage } from '@/api/simulation-training'
import { SimulationResourceCategory, SimulationResourceCategoryText } from '@/types/simulation'
import { myConfirm } from '@/utils/confirm'

export default Vue.extend({
  components: { ResourceSelectionModal, Draggable },
  data() {
    return {
      resourceSelectionModalVisible: false,
      enabled: true,
      dragging: false,
      list2: [
        { name: 'John', id: 0 },
        { name: 'Joao', id: 1 },
        { name: 'Jean', id: 2 },
      ],
    }
  },
  computed: {
    list(): SimulationTrainingCoursePage[] {
      return this.getSortList(this.$store.state.simulationTrainingModule.simulationTrainingCoursePageList)
    },
    activeSimulationTrainingCoursePage(): SimulationTrainingCoursePage {
      return this.$store.state.simulationTrainingModule.activeSimulationTrainingCoursePage
    },
    maxSort(): number {
      return this.$store.getters['simulationTrainingModule/maxSort']
    },
    isPublished() {
      return this.$store.state.simulationTrainingModule.selectedSimulationTraining.status === 1
    },
  },
  methods: {
    getSortList(list) {
      return list.sort((a, b) => a.sort - b.sort)
    },
    checkMove(e): boolean {
      if (e.draggedContext.futureIndex < 2 || e.draggedContext.index < 2)
        return false

      return true
    },
    async onEnd(e) {
      const { oldIndex } = e
      const { newIndex } = e
      if (oldIndex !== newIndex) {
        const newList = [...this.list].map((v) => {
          if (oldIndex < newIndex) {
            if (v.sort === oldIndex)
              v.sort = newIndex
            else if (v.sort > oldIndex && v.sort <= newIndex)
              v.sort -= 1
          }
          else if (oldIndex > newIndex) {
            if (v.sort === oldIndex)
              v.sort = newIndex
            else if (v.sort < oldIndex && v.sort >= newIndex)
              v.sort += 1
          }
          return v
        })
        this.$store.commit('simulationTrainingModule/setSimulationTrainingCoursePageList', newList)
        const sortedIds = newList.sort((a, b) => a.sort - b.sort).map(v => v.id!)
        await ibuidingAPI.vAStudyDesignTaskViewsBatchSortCreate({
          ids: sortedIds,
        })
        this.$store.dispatch('simulationTrainingModule/getSimulationTrainingCoursePageList')
        this.dragging = false
      }
    },
    switchingSteps(item) {
      this.$store.commit('simulationTrainingModule/setActiveSimulationTrainingCoursePage', item)
    },
    openResourceSelection() {
      this.resourceSelectionModalVisible = true
    },
    deleteItem(id) {
      myConfirm(this, {
        text: '确认删除此项？',
        resolve: () => {
          this.$store.dispatch('simulationTrainingModule/deleteSimulationTrainingCoursePage', {
            id,
          })
        },
      })
    },
    getTitle(type) {
      return SimulationResourceCategoryText[type]
    },
    async handleResourceSelectConfirm(item) {
      await this.$store.dispatch(
        'simulationTrainingModule/createEditSimulationTrainingCoursePage',
        {
          classType: Array.isArray(item) ? 6 : item.designFile?.classType,
          designFileId: item.designFile?.id,
          designTaskId: this.$store.state.simulationTrainingModule.selectedSimulationTraining.id,
          sort: this.maxSort + 1,
          viewSettings: Array.isArray(item) ? JSON.stringify(item) : undefined,
        },
      )
    },
  },
})
