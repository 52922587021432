
import { myConfirm } from "@/utils/confirm";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    tableData() {
      return this.$store.state.simulationTrainingModule.memberList;
    }
  },
  methods: {
    handleDelete(data) {
      myConfirm(this, {
        text: "确认删除此项？",
        resolve: () => {
          this.$store.dispatch("simulationTrainingModule/deleteMember", {
            designTaskId: this.$store.state.simulationTrainingModule.selectedSimulationTraining.id,
            targetIds: [data.userId]
          });
        }
      });
    }
  }
});
