
import Vue from 'vue'
import Header from './header/index.vue'
import HomeConfigure from './home-configure/index.vue'
import LoadPage from './load-page/index.vue'
import GeneralConfigurationPage from './general-configuration-page/index.vue'
import ExercisesPage from './exercises-page/index.vue'
import { SimulationResourceCategory, SimulationResourceCategoryText } from '@/types/simulation'
import GrayAroundWhiteInMiddle from '@/layout/gray-around-white-in-middle/index.vue'

export default Vue.extend({
  components: {
    Header,
    GrayAroundWhiteInMiddle,
    HomeConfigure,
    LoadPage,
    GeneralConfigurationPage,
    ExercisesPage,
  },
  computed: {
    componentName() {
      switch (
        this.$store.state.simulationTrainingModule.activeSimulationTrainingCoursePage?.classType
      ) {
        case SimulationResourceCategory.homePage:
          return 'HomeConfigure'
        case SimulationResourceCategory.loadingPage:
          return 'LoadPage'
        case SimulationResourceCategory.exercises:
          return 'ExercisesPage'
        default:
          return 'GeneralConfigurationPage'
      }
    },
  },
  async mounted() {
    if (!this.$store.state.simulationTrainingModule.selectedSimulationTraining.id) {
      this.$router.push('/collage/SimulationTraining')
      return
    }
    await this.$store.dispatch('simulationTrainingModule/getSimulationTrainingCoursePageList')
    if (!this.$store.state.simulationTrainingModule.simulationTrainingCoursePageList.length) {
      await this.$store.dispatch(
        'simulationTrainingModule/createEditSimulationTrainingCoursePage',
        {
          classType: SimulationResourceCategory.homePage,
          designTaskId: this.$store.state.simulationTrainingModule.selectedSimulationTraining.id,
          sort: 0,
        },
      )
      await this.$store.dispatch(
        'simulationTrainingModule/createEditSimulationTrainingCoursePage',
        {
          classType: SimulationResourceCategory.loadingPage,
          designTaskId: this.$store.state.simulationTrainingModule.selectedSimulationTraining.id,
          sort: 1,
        },
      )
    }
    this.$store.commit(
      'simulationTrainingModule/setActiveSimulationTrainingCoursePage',
      this.$store.state.simulationTrainingModule.simulationTrainingCoursePageList?.[0],
    )
  },
})
