
import Vue from "vue";
import TopBotLayout from "@/layout/top-bot-layout/top-bot-layout.vue";
import PracticalTrainingCourse from "@/features/practical-training-course-configure/practical-training-course/index.vue";
import Completion from "@/features/practical-training-course-configure/completion/index.vue";
import MemberConfiguration from "@/features/practical-training-course-configure/member-configuration/index.vue";
import { SimulationTraining } from "@/api/simulation-training";

export default Vue.extend({
  components: { TopBotLayout, PracticalTrainingCourse, Completion, MemberConfiguration },
  data() {
    return {
      activeName: "practicalTrainingCourse"
    };
  },
  computed: {
    isPublished() {
      return this.$store.state.simulationTrainingModule.selectedSimulationTraining.status === 1;
    },
    selectedSimulationTraining(): SimulationTraining {
      return this.$store.state.simulationTrainingModule.selectedSimulationTraining;
    }
  },
  methods: {
    async handlePublish() {
      this.$confirm(
        `${this.isPublished ? "确认取消发布该实训？" : "确认发布该实训？发布后该实训不可编辑！"}`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(async () => {
          const item = this.$store.state.simulationTrainingModule.selectedSimulationTraining;
          await this.$store.dispatch("simulationTrainingModule/createEditSimulationTraining", {
            ...item,
            status: this.isPublished ? 0 : 1
          });
          this.$store.commit(
            "simulationTrainingModule/setSelectedSimulationTraining",
            this.$store.state.simulationTrainingModule.simulationTrainingList.find(
              v => v.id === this.selectedSimulationTraining.id
            )
          );
        })
        .catch(() => {
          return;
        });
    }
  },
  created() {
    this.$store.commit("breadcrumbList", [
      {
        name: "定制仿真",
        path: "/collage/SimulationTraining"
      },
      {
        name: "实训课程"
      }
    ]);
  }
});
