
import Vue from 'vue'
import { Message } from 'element-ui'
import ResourceSelectionModal from '../resource-selection-modal/index.vue'
import type {
  DesignFile,
  DesignFileView,
  SimulationResource,
  UploadFile,
} from '@/api/customized-simulation-resources'
import {
  getSimulationResourceDetails,
} from '@/api/customized-simulation-resources'
import * as api from '@/api'
import { SimulationResourceCategory, SimulationResourceCategoryText } from '@/types/simulation'
import type { SimulationTrainingCoursePage } from '@/api/simulation-training'
export default Vue.extend({
  components: { ResourceSelectionModal },
  data() {
    return {
      tableData: [] as {
        designFile: DesignFile
        uploadFile: UploadFile
        viewFile: UploadFile
        designFileViewList: DesignFileView[]
      }[],
      resourceSelectionModalVisible: false,
    }
  },
  computed: {
    currentSimulationResource() {
      return this.$store.state.simulationTrainingModule.activeSimulationTrainingCoursePage
    },
    isPublished() {
      return this.$store.state.simulationTrainingModule.selectedSimulationTraining.status === 1
    },
  },
  watch: {
    currentSimulationResource: {
      handler(val: SimulationTrainingCoursePage) {
        if (
          ![SimulationResourceCategory.homePage, SimulationResourceCategory.loadingPage].includes(
            val.classType,
          )
        )
          this.init()
      },
    },
  },
  async mounted() {
    this.init()
  },
  methods: {
    getCategory(type) {
      return SimulationResourceCategoryText[type]
    },
    async init() {
      const res = await getSimulationResourceDetails({
        id: this.currentSimulationResource.designFileId,
      })
      if (res && !api.error(res))
        this.tableData = [res.data]
      else
        Message.error(res.error.message)
    },
    async handleResourceSelectConfirm(item: SimulationResource) {
      const activeSimulationTrainingCoursePage: SimulationTrainingCoursePage = this.$store.state
        .simulationTrainingModule.activeSimulationTrainingCoursePage
      await this.$store.dispatch(
        'simulationTrainingModule/createEditSimulationTrainingCoursePage',
        {
          classType: item.designFile.classType,
          designTaskId: activeSimulationTrainingCoursePage.designTaskId,
          sort: activeSimulationTrainingCoursePage.sort,
          viewSettings: activeSimulationTrainingCoursePage.viewSettings,
          id: activeSimulationTrainingCoursePage.id,
          designFileId: item.designFile.id,
        },
      )
      this.init()
    },
    handleReplace(item: DesignFile) {
      this.resourceSelectionModalVisible = true
    },
    viewModel(item: UploadFile) {
      this.$router.push({ path: '/model-view', query: { id: item.fileId } })
    },
  },
})
