
import { isEmpty, difference, uniq } from "lodash-es";
import Vue from "vue";
import { Message } from "element-ui";
import DialogViewQuestionSelected from "./DialogViewQuestionSelected.vue";
import type { PropType } from "vue/types/v3-component-props";
import Ellipsis from "../../../../components/Ellipsis.vue";
import { GetQuestionsResponse } from "@/api/BimOperationQuestion";
import QuestionItem from "@/features/QuestionBank/QuestionItem.vue";
import type { SimulationResource } from "@/api/customized-simulation-resources";
import {
  SimulationResourceCategory,
  SimulationResourceCategoryText,
} from "@/types/simulation";
import { ibuidingAPI } from "@/api/ibuidingAPI";
import * as api from "@/api";
enum ResourceType {
  Question,
  Simulation,
}
export default Vue.extend({
  components: { QuestionItem, DialogViewQuestionSelected },
  props: {
    visible: { type: Boolean },
    withQuestion: { type: Boolean },
    withResource: { type: Boolean },
    selectedQuestionsIds: { type: Array as PropType<string[]> },
  },
  data() {
    return {
      showDialogViewQuestionSelected: false,
      isSelectedAllQuestions: false,
      currentType: this.withQuestion
        ? ResourceType.Question
        : ResourceType.Simulation,
      serachInputValue: "",
      typeValue: -1,
      questionTypeValue: 0,
      typeOptions: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: SimulationResourceCategory.modelBrowsing,
          label:
            SimulationResourceCategoryText[
              SimulationResourceCategory.modelBrowsing
            ],
        },
        {
          value: SimulationResourceCategory.componentIntroduction,
          label:
            SimulationResourceCategoryText[
              SimulationResourceCategory.componentIntroduction
            ],
        },
        {
          value: SimulationResourceCategory.auxiliaryPositioning,
          label:
            SimulationResourceCategoryText[
              SimulationResourceCategory.auxiliaryPositioning
            ],
        },
        {
          value:
            SimulationResourceCategory.twoDimensionalAndThreeDimensionalComparison,
          label:
            SimulationResourceCategoryText[
              SimulationResourceCategory
                .twoDimensionalAndThreeDimensionalComparison
            ],
        },
      ],
      questionTypeOption: [
        { value: 0, label: "全部" },
        { value: 1, label: "单选题" },
        { value: 2, label: "多选题" },
        { value: 3, label: "判断题" },
      ],
      selectedItem: {
        designFile: { classType: 0, name: "", typeId: 0 },
        uploadFile: {
          fileConvertState: 0,
          fileExtention: "",
          fileId: "",
          fileName: "",
          fileSize: 0,
          fileUrl: "",
        },
      } as SimulationResource,
      questionCatalogue: [] as any,
      questions: [] as any,
      selectedIds: [] as string[],
    };
  },
  computed: {
    isSimulationType(): boolean {
      return this.currentType === ResourceType.Simulation;
    },
    catalogueTreeData(): {
      label: string;
      id: string;
      children?: {
        label: string;
        id: string;
      }[];
    }[] {
      const catalogueList =
        this.$store.state.customizedSimulationResourcesModule.catalogueList;
      const treeData = catalogueList.map((catalogue) => ({
        label: catalogue.name,
        id: catalogue.id,
      }));
      return [
        {
          label: "全部",
          id: "0",
          children: treeData,
        },
      ];
    },
    questionCatalogueTreeData(): {
      label: string;
      id: string;
      children?: {
        label: string;
        id: string;
      }[];
    }[] {
      const treeData = this.questionCatalogue.map((catalogue) => ({
        label: catalogue.name,
        id: catalogue.id,
      }));
      return [
        {
          label: "全部",
          id: "0",
          children: treeData,
        },
      ];
    },
    tableData(): SimulationResource[] {
      const simulationResourceList: SimulationResource[] =
        this.$store.state.customizedSimulationResourcesModule
          .simulationResourceList;
      return simulationResourceList.filter((v) => {
        if (this.typeValue === -1) {
          return v.designFile.name.includes(this.serachInputValue);
        } else {
          return (
            v.designFile.name.includes(this.serachInputValue) &&
            v.designFile.classType === this.typeValue
          );
        }
      });
    },
    filteredQuestions(): any[] {
      return this.questions
        .filter((v) => {
          if (this.questionTypeValue === 0) return v;
          else return v.classType === this.questionTypeValue;
        })
        .filter((v) => v.name.includes(this.serachInputValue));
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          console.log(111);
          if (this.selectedQuestionsIds)
            this.$set(this, "selectedIds", this.selectedQuestionsIds);
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.$store.dispatch(
      "customizedSimulationResourcesModule/getCatalogueList"
    );
    await this.$store.dispatch(
      "customizedSimulationResourcesModule/getSimulationResources",
      {}
    );
    await this.getQuestionCatalogue();
  },
  methods: {
    changedSort(val) {
      // console.log(`传出的重新的顺序为`, val);
      this.selectedIds = val;
      this.showDialogViewQuestionSelected = false;
    },
    // 判断当前练习题是否已经全选
    // true则代表全选 false则代表没有全选
    isCurrentPageAllQuestionsSelected() {
      const ids = this.filteredQuestions.map((item) => item.id);
      return difference(ids, this.selectedIds).length === 0;
    },
    // 点击全选按钮
    handleSelectedAllQuestionsChange() {
      const ids = this.filteredQuestions.map((item) => item.id);
      if (this.isSelectedAllQuestions) {
        this.selectedIds = uniq(this.selectedIds.concat(ids));
      } else {
        const arr = difference(this.selectedIds, ids);
        this.selectedIds = arr;
      }
    },
    handleNodeClick(data) {
      this.$store.dispatch(
        "customizedSimulationResourcesModule/getSimulationResources",
        {
          catalogeId: data.id === "0" ? undefined : data.id,
        }
      );
    },
    handleQuestionNodeClick(data) {
      this.getQuestions({ id: data.id === "0" ? undefined : data.id });
    },
    async getQuestions({ id }) {
      const res = await ibuidingAPI.vAStudyDesignQuestionsPageListCreate({
        maxResultCount: 999,
        skipCount: 0,
        typeId: id,
      });
      if (res && !api.error(res)) {
        this.$set(this, "questions", res.data.items);
        const re = this.isCurrentPageAllQuestionsSelected();
        this.isSelectedAllQuestions = re;
      } else Message.error(res.error.message);
    },
    selectItem(item) {
      this.selectedItem = item;
    },
    async confirm() {
      if (
        this.currentType === ResourceType.Simulation &&
        !this.selectedItem?.designFile?.typeId
      ) {
        this.$message.error("请选择一个资源");
        return;
      }
      if (
        this.currentType === ResourceType.Question &&
        !this.selectedIds.length
      ) {
        this.$message.error("请选择问题");
        return;
      }
      this.$emit("update:visible", false);
      this.$emit(
        "onConfirm",
        this.currentType === ResourceType.Simulation
          ? this.selectedItem
          : this.selectedIds
      );
    },
    getCategory(type) {
      return SimulationResourceCategoryText[type];
    },
    checkModel(id) {
      this.$router.push({ path: "/model-view", query: { id } });
    },
    async getQuestionCatalogue() {
      const res = await ibuidingAPI.vAStudyDesignQuestionTypesPageListCreate({
        maxResultCount: 999,
        skipCount: 0,
      });
      if (res && !api.error(res)) this.questionCatalogue = res.data.items;
      else Message.error(res.error.message);
    },
    handleChange(id) {
      if (this.selectedIds.includes(id)) {
        this.selectedIds.splice(
          this.selectedIds.findIndex((v) => v === id),
          1
        );
      } else {
        this.selectedIds.push(id);
      }
      this.isSelectedAllQuestions = this.isCurrentPageAllQuestionsSelected();
    },
  },
});
