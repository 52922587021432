
import Vue from "vue";
import TopBotLayout from "@/layout/top-bot-layout/top-bot-layout.vue";
import Header from "./header/index.vue";

import MemberConfigurationTable from "./member-configuration-table/index.vue";

export default Vue.extend({
  components: { TopBotLayout, Header, MemberConfigurationTable },
  computed: {
    memberList() {
      return this.$store.state.simulationTrainingModule.memberList;
    }
  },
  mounted() {
    this.$store.dispatch("simulationTrainingModule/getMemberDetails", {
      designTaskId: this.$store.state.simulationTrainingModule.selectedSimulationTraining.id
    });
  }
});
